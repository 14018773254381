import React from "react";
import { Button, Col } from "antd";
import { useResponsive } from "@app/common/hooks/useResponsive";
import * as S from "./Home.style";
import * as CommonStyles from "@styles/CommonStyles.style";
import { useTranslation } from "react-i18next";

interface IHomeProps {
  id: string;
}

const Home: React.FC<IHomeProps> = ({ id }) => {
  const { t } = useTranslation();
  const { mobileOnly } = useResponsive();

  return (
    <CommonStyles.WrapperRowWithBackground id={id}>
      <CommonStyles.FullyCenteredRow>
        <Col>
          {!mobileOnly && (
            <CommonStyles.FullyCenteredRow>
              <S.ContainerRow style={{height: "425px"}}/>
            </CommonStyles.FullyCenteredRow>
          ) }

          {mobileOnly && (
              <CommonStyles.FullyCenteredRow>
                <S.ContainerRow style={{height: "120px"}}/>
              </CommonStyles.FullyCenteredRow>
          ) }

          <CommonStyles.FullyLeftAligned>
            {!mobileOnly ? (
                <CommonStyles.H2Title>
                  {t("pages.main.sections.home.hero1")}
                </CommonStyles.H2Title>
            ): (
                <CommonStyles.HM2Title>
                  {t("pages.main.sections.home.hero1")}
                </CommonStyles.HM2Title>
            )}
          </CommonStyles.FullyLeftAligned>

          <CommonStyles.FullyLeftAligned>
            {!mobileOnly ? (
                <CommonStyles.H1Title>
                  {t("pages.main.sections.home.hero2")}
                </CommonStyles.H1Title>
            ): (
                <CommonStyles.HM1Title>
                  {t("pages.main.sections.home.hero2")}
                </CommonStyles.HM1Title>
            )}
          </CommonStyles.FullyLeftAligned>

          <CommonStyles.FullyLeftAligned>
            {!mobileOnly ? (
                <CommonStyles.H1Text $isLight>
                  {t("pages.main.sections.home.explanation")}
                </CommonStyles.H1Text>
            ): (
                <CommonStyles.HM1Text $isLight>
                  {t("pages.main.sections.home.explanation")}
                </CommonStyles.HM1Text>
            )}
          </CommonStyles.FullyLeftAligned>

          <CommonStyles.FullyLeftAligned>
            <Button
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_APPLICATION_PATH}`,
                  "_blank"
                );
              }}
              type="primary"
              size="large"
            >
              {t("pages.main.sections.home.getStarted")}
            </Button>
          </CommonStyles.FullyLeftAligned>

          {!mobileOnly && (
            <CommonStyles.FullyCenteredRow>
              <S.ContainerRow style={{height: "40px"}}/>
            </CommonStyles.FullyCenteredRow>
          ) }

        </Col>
      </CommonStyles.FullyCenteredRow>
    </CommonStyles.WrapperRowWithBackground>
  );
};

export default Home;
