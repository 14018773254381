import React, { useMemo } from "react";
import { useResponsive } from "@app/common/hooks/useResponsive";
import {Button, Col, Row} from "antd";
import { Overlay } from "@app/components/Overlay";
import { NavigatorItem } from "@components/HeaderItem";
import { NavigatorItemType } from "@common/types";
import { LanguagePicker } from "@app/components/LanguagePicker";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./MainSider.style";
import { useTranslation } from "react-i18next";

interface IMainSiderProps {
  isCollapsed: boolean;
  toggleSider: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<IMainSiderProps> = ({ isCollapsed, toggleSider }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { mobileOnly, tabletOnly } = useResponsive();

  const ROOT_PATH = useMemo(() => "/", []);
  const isInRootPath = location.pathname === ROOT_PATH;

  const SIDER_ITEMS = useMemo(
    (): NavigatorItemType[] => [
      {
        key: "home",
        title: t("sider.home"),
        visibility: true,
        onClick: () => {
          isInRootPath
            ? document.getElementById("home")?.scrollIntoView()
            : navigate(ROOT_PATH);
          toggleSider(!isCollapsed);
        },
      },
      {
        key: "features",
        title: t("sider.features"),
        visibility: isInRootPath,
        onClick: () => {
          document.getElementById("features")?.scrollIntoView();
          toggleSider(!isCollapsed);
        },
      },
      /*{
        key: "pricing",
        title: t("sider.pricing"),
        visibility: isInRootPath,
        onClick: () => {
          document.getElementById("pricing")?.scrollIntoView();
          toggleSider(!isCollapsed);
        },
      },*/
      {
        key: "contact",
        title: t("sider.contact"),
        visibility: isInRootPath,
        onClick: () => {
          document.getElementById("contact")?.scrollIntoView();
          toggleSider(!isCollapsed);
        },
      },
    ],
    [location, t]
  );

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={0}
        collapsible={true}
        width={260}
      >
        <Col>
          <S.CollapseIconContainer>
            <S.IconWrapper>
              <S.MobileBurger
                onClick={() => toggleSider(!isCollapsed)}
                isCross={!isCollapsed}
              />
            </S.IconWrapper>
          </S.CollapseIconContainer>
        </Col>
        <S.SiderContentContainer>
          <Col style={{ height: "100%" }}>
            <Button style={{margin: "0.5rem", backgroundColor:"var(--secondary-color)", color:"var(--text-main-color)"}}
                    onClick={() => {
                      window.open(
                          `${process.env.REACT_APP_APPLICATION_PATH}`,
                          "_blank"
                      );
                    }}
                    type="primary"
                    size="middle"
            >
              {t("pages.main.sections.home.getStarted")}
            </Button>
          </Col>
        </S.SiderContentContainer>
        <S.SiderContentContainer>
          <Col style={{ height: "100%" }}>
            {SIDER_ITEMS.filter((item) => item.visibility).map((item) => (
              <NavigatorItem color="var(--white)" item={item} />
            ))}
            <LanguagePicker onAfterClickItem={() => toggleSider(true)} />
          </Col>
        </S.SiderContentContainer>
      </S.Sider>

      {(mobileOnly || tabletOnly) && (
        <Overlay
          onClick={() => toggleSider(!isCollapsed)}
          show={!isCollapsed}
        />
      )}
    </>
  );
};

export default MainSider;
