import { ITheme } from "@common/interfaces";
import { ThemeType } from "./../../common/types";
import { css } from "styled-components";
import { BASE_COLORS } from "@common/constants";
import { lightColorsTheme } from "@app/styles/themes/light/LightTheme";
import { hexToRGB } from "@utils/utils";

interface IThemeContainer {
  light: ITheme;
  dark: ITheme;
}

export const themeObject: IThemeContainer = {
  light: lightColorsTheme,

  // TO DO: Create Dark Color Schema.
  dark: {} as ITheme,
};

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --primary-color: ${themeObject[theme].primaryColor};
  --secondary-color: ${themeObject[theme].secondaryColor};
  --primary-color-hover: ${themeObject[theme].primaryColorHover};
  --layout-header-bg-color: ${themeObject[theme].headerBgColor};
  --layout-body-bg-color: ${themeObject[theme].bodyBgColor};
  --layout-sider-bg-color: ${themeObject[theme].siderBgColor};
  --layout-footer-bg-color: ${themeObject[theme].footerBgColor};
  --text-main-color: ${themeObject[theme].textMainColor};
  --text-light-color: ${themeObject[theme].textLightColor};
  --text-extra-light-color: ${themeObject[theme].textExtraLightColor};
  --text-home-main-color: ${themeObject[theme].textHomeMainColor};
  --text-home-light-color: ${themeObject[theme].textHomeLightColor};
  --text-home-extra-light-color: ${themeObject[theme].textHomeExtraLightColor};
  --text-home-highlight-color: ${themeObject[theme].textHomeHighlightColor};
  --error-color: ${themeObject[theme].errorColor};
  --warning-color: ${themeObject[theme].warningColor};
  --success-color: ${themeObject[theme].successColor};
  --notification-success-color: ${themeObject[theme].notificationSuccessColor};
  --notification-primary-color: ${themeObject[theme].notificationPrimaryColor};
  --notification-warning-color: ${themeObject[theme].notificationWarningColor};
  --notification-error-color: ${themeObject[theme].notificationErrorColor};
  --box-shadow-color: ${themeObject[theme].boxShadowColor};
  --icon-container-color: ${themeObject[theme].iconContainerLightColor};
  --flag-icon-bg-color-hover: ${themeObject[theme].flagIconBgColorHover};
  --scroll-color: ${themeObject[theme].scrollColor};
`;

export const lightThemeVariables = css`
  ${getThemeVariables("light")}
`;

export const commonThemeVariables = css`
  color-scheme: light;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --violet: ${BASE_COLORS.violet};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --pink: ${BASE_COLORS.pink};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`;
