import React, { useCallback, useMemo } from "react";
import {Button, Col, Row} from "antd";
import { LogoWithTitle } from "../LogoWithTitle";
import { NavigatorItemType } from "@common/types";
import { NavigatorItem } from "../HeaderItem";
import { useLanguage } from "@common/hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "../LanguagePicker";
import mariticsLogo from "@assets/images/maritics_logo.svg";
import * as S from "./ExpandedHeader.style";
import * as CommonStyle from "@app/styles/CommonStyles.style";
import { useLocation, useNavigate } from "react-router-dom";

const ExpandedHeader: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();

  const ROOT_PATH = useMemo(() => "/", []);
  const isInRootPath = location.pathname === ROOT_PATH;

  const EXPANDED_NAVIGATOR_ITEMS: NavigatorItemType[] = useMemo(
    () => [
      {
        key: "home",
        title: t("header.links.home"),
        visibility: true,
        onClick: () => {
          isInRootPath
            ? document.getElementById("home")?.scrollIntoView()
            : navigate(ROOT_PATH);
        },
      },
      {
        key: "features",
        title: t("header.links.features"),
        visibility: isInRootPath,
        onClick: () => {
          document.getElementById("features")?.scrollIntoView();
        },
      },
      /*{
        key: "pricing",
        title: t("header.links.pricing"),
        visibility: isInRootPath,
        onClick: () => {
          document.getElementById("pricing")?.scrollIntoView();
        },
      },*/
      {
        key: "contact",
        title: t("header.links.contact"),
        visibility: isInRootPath,
        onClick: () => {
          document.getElementById("contact")?.scrollIntoView();
        },
      },
    ],
    [language, isInRootPath]
  );

  return (
    <CommonStyle.WrapperRow>
      <S.HeaderContainer>
        <Col>
          <LogoWithTitle title="MARITICS" logo={mariticsLogo} />
        </Col>

        <Row>
          <Col>
            <Button style={{margin: "0.5rem"}}
                onClick={() => {
                  window.open(
                      `${process.env.REACT_APP_APPLICATION_PATH}`,
                      "_blank"
                  );
                }}
                type="primary"
                size="middle"
            >
              {t("pages.main.sections.home.getStarted")}
            </Button>
          </Col>
          {EXPANDED_NAVIGATOR_ITEMS.filter((item) => item.visibility).map(
            (item) => (
              <Col>
                <NavigatorItem item={item} />
              </Col>
            )
          )}
          <LanguagePicker />
        </Row>
      </S.HeaderContainer>
    </CommonStyle.WrapperRow>
  );
};

export default ExpandedHeader;
