import { ITheme } from "@common/interfaces";

export const lightColorsTheme: ITheme = {
  primaryColor: "#01509A",
  primaryColorHover: "#FEAF65",
  secondaryColor: "#FEAF65",
  headerBgColor: "#FFFFFF",
  bodyBgColor: "#FFFFFF",
  siderBgColor: "#01509A",
  footerBgColor: "#F8FBFB",
  textMainColor: "#404040",
  textLightColor: "#9A9B9F",
  textExtraLightColor: "#BEC0C6",
  textHomeMainColor: "#FFFFFF",
  textHomeLightColor: "#BEC0C6",
  textHomeExtraLightColor: "#9A9B9F",
  textHomeHighlightColor: "#00DFFF",


  errorColor: "#FF5252",
  warningColor: "#FFB155",
  successColor: "#30AF5B",

  notificationPrimaryColor: "#D7EBFF",
  notificationSuccessColor: "#EFFFF4",
  notificationWarningColor: "#FFF4E7",
  notificationErrorColor: "#FFE2E2",

  flagIconBgColorHover: "#F0EBEF",
  iconContainerLightColor: "#015091",
  boxShadowColor: "0 2px 8px 0 rgba(0, 0, 0, 0.07)",
  scrollColor: "#c5d3e0",
};

// primaryGradient: "linear-gradient(211.49deg, #006CCF 15.89%, #00509A 48.97%)",
// light: "#C5D3E0",
// secondary: "#0085FF",
// error: "#FF5252",
// warning: "#FFB155",
// success: "#30AF5B",
// background: BASE_COLORS.white,
// secondaryBackground: "#F8FBFF",
// secondaryBackgroundSelected: shadeColor("#F8FBFF", -5),
// additionalBackground: "#ffffff",
// collapseBackground: "rgb(0, 108, 207)",
// timelineBackground: "#F8FBFF",
// siderBackground: "#ffffff",
// spinnerBase: "#339CFD",
// scroll: "#c5d3e0",
// border: "#cce1f4",
// textMain: "#404040",
// textLight: "#9A9B9F",
// textSuperLight: "#BEC0C6",
// textSecondary: BASE_COLORS.white,
// textDark: "#404040",
// textSiderPrimary: "#FFB765",
// textSiderSecondary: "#ffffff",
// subText: "rgba(0, 0, 0, 0.45)",
// shadow: "rgba(0, 0, 0, 0.07)",
// boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.07)",
// boxShadowHover: "0 4px 16px 0 rgba(0, 0, 0, 0.2)",
// notificationSuccess: "#EFFFF4",
// notificationPrimary: "#D7EBFF",
// notificationWarning: "#FFF4E7",
// notificationError: "#FFE2E2",
// heading: "#13264d",
// borderBase: "#bec0c6",
// disable: "rgba(0, 0, 0, 0.25)",
// disabledBg: "#c5d3e0",
// layoutBodyBg: "#ffffff",
// layoutHeaderBg: "#ffffff",
// layoutSiderBg: "linear-gradient(261.31deg, #006ccf -29.57%, #00509a 121.11%)",
// layoutFooterBg: "#f5f5f5",
// inputPlaceholder: "#404040",
// itemHoverBg: "#f5f5f5",
// backgroundColorBase: "#F5F5F5",
// avatarBg: "#ccc",
// alertTextColor: BASE_COLORS.white,
// breadcrumb: "rgba(0, 0, 0, 0.45)",
// icon: "#a9a9a9",
// iconHover: "rgba(0, 0, 0, 0.75)",
